<template>
  <div class="general-information-subview">
    <Section class="section--border-bottom bg-gray-lightestest">
      <Container>
        <Timeline :index="step" :total="states.length" :lastStepToRight="true" :stepsData="states" />
      </Container>
    </Section>
    <Section>
      <Container>
        <template v-if="noOrder">
          <p>
            Aucun ordre de saisie n’est renseigné.
          </p>
        </template>

        <template v-if="studiedVariable.length <= 0">
          <p>
            Aucune variable n'a été ajouté.
          </p>
        </template>

        <template v-if="!noOrder && studiedVariable.length > 0">
          <NotationCard v-if="fetched" :reservation="reservation" :isOwner="owner" :isInDashboard="false" />
        </template>
      </Container>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Timeline from '@/components/layout/Timeline.vue'
import NotationCard from '@/components/base/NotationCard.vue'

export default {
  name: 'NotationStateSubview',
  components: {
    Section,
    Container,
    NotationCard,
    Timeline,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const statesUids = [
      'ENPREPARATION',
      'RESERVE',
      'ENCOURS',
      'ATTENTESYNCHRO',
      'PARTIELLEMENTREALISE',
      'REALISE',
    ]

    return {
      step: 0,
      states: [],
      notation: {},
      frames: [],
      reservation: {},
      reservations: [],
      reservationStatus: this.$store.state.app.reservationStatus,
      stades: this.$store.state.app.stades,
      studiedVariable: [],
      owner: false,
      statesUids,
      noOrder: false,
      fetched: false,
      editable: this.isEditable,
    }
  },

  mounted() {
    this.getDataNotationState('root')
    this.emitter.on('notation-refresh', this.getDataNotationState)
  },

  methods: {
    async getDataNotationState(from = null) {
      this.emitter.emit('open-loader')
      // this.emitter.emit("open-loader");
      if (this.$route.params.id) {
        this.fetchService
          .get(`essai/${this.$route.params.id}`)
          .then((response) => {
            // this.state = reservation.etat.uid

            Promise.all([
              this.fetchService
                .get(
                  `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`,
                )
                .then((responseNotation) => {
                  this.notation = responseNotation.data.pop()
                  // si pas de d'odre on coupe
                  if (
                    this.notation.saisie_objet.uid !== 'MODALITE'
                    && (
                      this.notation.saisie_ordre === null
                      || (this.notation.saisie_ordre.uid === 'MANUEL'
                        && this.notation.chemin_personnalise === null)
                    )
                  ) {
                    this.noOrder = true
                  }
                }),
            ]).then(() => {
              if (this.noOrder) {
                this.emitter.emit('close-loader')
              } else {
                Promise.all([
                  this.fetchService
                    .get(
                      `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/variable_etudiee`,
                      { limit: 0 },
                    )
                    .then((responseVar) => {
                      this.studiedVariable = responseVar.data
                    }),

                  this.fetchService
                    .get(
                      `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/trames`,
                      { limit: 0 },
                    )
                    .then((responseframes) => {
                      this.frames = responseframes.data
                      this.frames = { ...this.frames, ...{ terrainIndex: 0 } }
                      this.frames = { ...this.frames, ...{ laboIndex: 0 } }
                    }),
                  this.fetchService
                    .get(
                      `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/reservation`,
                      { limit: 0 },
                    )
                    .then((responseReservation) => {
                      this.reservations = responseReservation.data
                    }),
                ]).then(async () => {
                  if (this.reservations.length > 0) {
                    this.reservation = this.reservations.pop()
                  } else {
                    this.reservation = {
                      id: null,
                      etat: {
                        uid: 'ENPREPARATION',
                        designation: 'En préparation',
                      },
                      utilisateur: this.$store.state.auth.user.data,
                    }
                  }

                  this.notation.variables = this.studiedVariable

                  this.reservation.essai = response.data
                  this.reservation.evenement = this.task
                  this.reservation.evenement.designation = this.task.designation ?? 'Notation'
                  this.reservation.notation = this.notation
                  this.reservation.frames = this.frames

                  this.reservation.notation.notes = await this.getNotes()
                  this.reservation.notation.startingnote = this.reservation.notation.notes

                  this.states = []
                  this.statesUids.forEach((element) => {
                    const state = this.reservationStatus.find(
                      (item) => item.uid === element,
                    )
                    if (state) {
                      this.states.push(state.designation)
                    }
                  })

                  this.step = this.statesUids.findIndex(
                    (v) => v === this.reservation.etat.uid,
                  )

                  if (
                    this.reservation?.utilisateur?.id
                    === this.$store.state.auth.user.data.id
                  ) {
                    this.owner = true
                    if (
                      this.reservation.id !== null
                      && (
                        this.reservation.etat.uid === 'ENCOURS'
                        || this.reservation.etat.uid === 'RESERVE'
                      )
                      && !this.notationService.getItem(
                        'reservations',
                        this.reservation.id,
                      )
                    ) {
                      this.notationService.setItem(
                        'reservations',
                        this.reservation,
                      )
                    }
                  }

                  if (this.reservation?.id !== null && this.notationService.getItem('reservations', this.reservation.id)) {
                    this.reservation = this.notationService.getItem('reservations', this.reservation.id)
                  }

                  if (this.reservation?.etat?.uid === 'ENPREPARATION') {
                    this.editable = true
                  } else {
                    this.editable = false
                  }
                  console.log('from', from)
                  this.fetched = true
                  this.emitter.emit('close-loader')
                })
              }
            })
          })
      }
    },

    getNotes() {
      return new Promise((resolve) => {
        this.fetchService
          .get(
            `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/note`,
            { limit: 0 },
          )
          .then((responseNote) => {
            const notes = responseNote.data.map((note) => {
              let euid = `${note.microparcelle.id}-${note.variable.id}`
              let index = 1

              if (note?.organe) {
                euid = `${euid}-${note.organe.id}-${parseInt(note.numeroechantillon, 10)}`
              } else {
                if (parseInt(note.numeroechantillon, 10)) {
                  index = parseInt(note.numeroechantillon, 10)
                }
                euid = `${euid}-${index}`
              }

              return {
                id: note.id,
                euid,
                valeur: note.valeur,
                valeurautre: note.valeurautre,
                nonapplicable: note.nonapplicable,
                valeur_liste_id: note.valeur_liste,
                numeroechantillon: (parseInt(note.numeroechantillon, 10)) ? parseInt(note.numeroechantillon, 10) : index,
                variable_id: note.variable.id,
                organe_id: note?.organe?.id,
                microparcelle_id: note?.microparcelle?.id,
                error: false,
              }
            })
            resolve(notes)
          })
      })
    },

    setLocalStorage(reservationId) {
      const rp = this.$route.params
      this.notationService.setCollectionFromDb(
        'essai',
        `essai/${this.$route.params.id}`,
        reservationId,
      )
      this.notationService.setCollectionFromDb(
        'variables',
        `essai/${rp.id}/evenement/${rp.tid}/fiche/notation/${this.notation.id}/variable_etudiee?limit=0`,
        reservationId,
      )
    },
  },
}
</script>
